import React from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ConfigProvider, Layout, Menu, theme } from 'antd';

export const Root: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { darkAlgorithm } = theme;
    const {
        token: { borderRadiusLG },
    } = theme.useToken();

    return (
        <ConfigProvider theme={{ algorithm: darkAlgorithm }}>
            <Layout style={{ minHeight: '100vh' }}>
                <Layout.Header style={{ display: 'flex', padding: 0 }}>
                    <Menu
                        mode="horizontal"
                        selectedKeys={[location.pathname]}
                        style={{ display: 'flex', flex: 1, justifyContent: 'center', minWidth: 0 }}
                        items={[
                            { key: '/', label: 'Cabinets', onClick: () => navigate('/') },
                            { key: '/cost', label: 'Cost', onClick: () => navigate('/cost') },
                            { key: '/builder', label: 'Builder', onClick: () => navigate('/builder') },
                            { key: '/calculator', label: 'Calculator', onClick: () => navigate('/calculator') },
                            { key: '/misc', label: 'Misc', onClick: () => navigate('/misc') },
                        ]}
                    />
                </Layout.Header>
                <Layout.Content style={{ padding: '0 48px', height: '100%' }}>
                    <div
                        style={{
                            background: '#141414',
                            padding: 24,
                            minHeight: 280,
                            borderRadius: borderRadiusLG,
                            height: '100%',
                            marginTop: 24,
                        }}
                    >
                        <Outlet />
                    </div>
                </Layout.Content>

                <Layout.Footer style={{ textAlign: 'center' }}>
                    LED Calculator ©{new Date().getFullYear()} mychurchled.com
                </Layout.Footer>
            </Layout>
        </ConfigProvider>
    );
}
