import React, { useCallback, useMemo } from 'react';
import { Alert, Card, Col, Form, Input, InputNumber, Row, Select, Space, Statistic, Typography } from 'antd';

import { useCabinetStore, useQuoteStore } from 'store';
import { BuildQuoteButton } from 'components/generateQuote';
import { calculator } from 'utils/calculator';

interface IFormValues {
    width: number;
    height: number;
}

export const Builder: React.FC = () => {
    const { setRequestedWidth, setRequestedHeight } = useQuoteStore((state) => state);
    const { selectedCabinet, cabinets, setSelectedCabinet } = useCabinetStore((state) => state);
    const [form] = Form.useForm<IFormValues>();

    const onCabinetSelect = useCallback((cabinetId: string) => {
        const cabinet = cabinets.find((cabinet) => cabinet.id === cabinetId);
        if (!cabinet) {
            return;
        }

        setSelectedCabinet(cabinet);
    }, [cabinets, setSelectedCabinet]);

    const dimensions = useMemo(() => {
        if (!selectedCabinet) {
            return null;
        }

        const widthInMillimeters = selectedCabinet.module.width * selectedCabinet.modules.width;
        const heightInMillimeters = selectedCabinet.module.height * selectedCabinet.modules.height;

        return `${ widthInMillimeters }mm x ${ heightInMillimeters }mm`;
    }, [selectedCabinet]);

    if (!selectedCabinet) {
        //TODO: enable a selector to choose a cabinet
        return (
            <div>No cabinet selected</div>
        );
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
                <div>
                    <Typography.Title level={3}>Build With: { selectedCabinet.name } ({ selectedCabinet.description })</Typography.Title>
                    <Typography.Text type="secondary">${ parseFloat(selectedCabinet.cost) * 2 } per cabinet. { dimensions }</Typography.Text>
                </div>
                <Space.Compact>
                    <BuildQuoteButton />
                    <Select style={{ flex: 0.5 }} onChange={onCabinetSelect} defaultValue={selectedCabinet?.id || undefined}>
                        { cabinets.map((cabinet) => (
                            <Select.Option key={cabinet.id} value={cabinet.id}>
                                { cabinet.name }
                            </Select.Option>
                        ))}
                    </Select>
                </Space.Compact>
            </div>

            <Form<IFormValues>
                form={form}
                layout="vertical"
            >
                <Card title="Requested Sizes" size="small" style={{ marginBottom: '16px' }}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Width (ft)" name="width" rules={[{ required: true }]}>
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    step={0.1}
                                    suffix="feet"
                                    style={{ width: '100%' }}
                                    onChange={(value) => setRequestedWidth(value || 0)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Height (ft)" name="height" rules={[{ required: true }]}>
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    step={0.1}
                                    suffix="feet"
                                    style={{ width: '100%' }}
                                    onChange={(value) => setRequestedHeight(value || 0)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Form.Item noStyle shouldUpdate={(p: IFormValues, c: IFormValues) => p.height !== c.height || p.width !== c.width}>
                    {({ getFieldsValue }) => {
                        const { width, height } = getFieldsValue();
                        const result = calculator({ cabinet: selectedCabinet, width, height, items: [] });

                        return (
                            <React.Fragment>
                                <Card title="Screen Details" size="small" style={{ marginBottom: '16px' }}>
                                    <Row gutter={16}>
                                        <Col span={6}>
                                            <Form.Item label="Cabinets Width" extra="The number of cabinets required to cover the width.">
                                                <InputNumber value={result.cabinets.width} suffix="cabinets" disabled style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Cabinets Height" extra="The number of cabinets required to cover the height.">
                                                <InputNumber value={result.cabinets.height} suffix="cabinets" disabled style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Total Cabinets" extra="The total number of cabinets required.">
                                                <InputNumber value={result.totalCabinets} suffix="cabinets" disabled style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Weight (lbs)" extra="The total weight of the cabinets.">
                                                <InputNumber
                                                    value={result.weight.lb}
                                                    suffix="lbs"
                                                    disabled
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={6}>
                                            <Form.Item label="Actual Width (ft)" extra="The actual width of the screen in feet.">
                                                <InputNumber
                                                    value={result.dimensionsInFeet.width}
                                                    suffix="feet"
                                                    disabled
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Actual Height (ft)" extra="The actual height of the screen in feet.">
                                                <InputNumber
                                                    value={result.dimensionsInFeet.height}
                                                    suffix="feet"
                                                    disabled
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Physical Resolution" extra="The resolution of the cabinet in pixels.">
                                                <Input value={result.resolutionFormatted} suffix="pixels" disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Aspect Ratio" extra="The aspect ratio of the screen resolution.">
                                                <Input value={result.aspectRatioFormatted} disabled />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>

                                <Row gutter={16}>
                                    <Col span={9}>
                                        <Alert
                                            showIcon
                                            type="info"
                                            message="Price Estimate"
                                            description="This is a rough estimate and does not include additional components like power supplies, controllers, or mounting hardware."
                                            style={{ marginBottom: '25px' }}
                                        />
                                    </Col>
                                    <Col span={3}>
                                        <Statistic title="Estimated Price" value={result.price} prefix="$" precision={2} />
                                    </Col>
                                    <Col span={3}>
                                        <Statistic title="NovaStar Model" value={result?.novastar?.model || 'N/A'} />
                                    </Col>
                                    <Col span={3}>
                                        <Statistic title="NovaStar Price" value={result?.novastar?.price || 'N/A'} prefix="$" precision={2} />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        );
                    }}
                </Form.Item>
            </Form>
        </React.Fragment>
    );
};
