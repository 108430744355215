import React from 'react';
import { Card, Col, Divider, Form, Input, InputNumber, Row } from 'antd';

import { gcd } from 'utils';

interface IFormValues {
    module: {
        width: number;
        height: number;
        resolution: {
            width: number;
            height: number;
        };
        pixelPitch: number;
    };
    cabinet: {
        modules: {
            width: number;
            height: number;
        };
        weight: number;
        power: {
            max: number;
        },
    };
    screen: {
        cabinets: {
            width: number;
            height: number;
        };
    };
    pricing: {
        perSqMeter: number;
    }
}

export const Calculator: React.FC = () => {
    const [form] = Form.useForm<IFormValues>();

    return (
        <Form<IFormValues>
            form={form}
            layout="vertical"
            initialValues={{
                module: {
                    width: 250,
                    height: 250,
                    resolution: {
                        width: 88,
                        height: 88,
                    },
                    pixelPitch: 2.84,
                },
                cabinet: {
                    modules: {
                        width: 2,
                        height: 2,
                    },
                    weight: 5.6,
                    power: {
                        max: 150,
                    },
                },
            }}
        >
            <Card title="Block Module" size="small" style={{ marginBottom: '16px' }}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            label="Width"
                            name={['module', 'width']}
                            extra="The width of a single module in millimeters."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={0}
                                suffix="mm"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Height"
                            name={['module', 'height']}
                            extra="The height of a single module in millimeters."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={0}
                                suffix="mm"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Resolution Width"
                            name={['module', 'resolution', 'width']}
                            extra="The number of pixels in the width of the module."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={0}
                                suffix="px"
                                inputMode="numeric"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Resolution Height"
                            name={['module', 'resolution', 'height']}
                            extra="The number of pixels in the height of the module."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={0}
                                suffix="px"
                                inputMode="numeric"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            label="Pixel Pitch"
                            name={['module', 'pixelPitch']}
                            extra="The distance between pixels in millimeters."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={3}
                                suffix="mm"
                                inputMode="decimal"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Physical Pixels"
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.module?.resolution !== c?.module?.resolution}
                        >
                            {({ getFieldValue }) => {
                                const resolution = getFieldValue(['module', 'resolution']);
                                if (!resolution) {
                                    return <Input disabled />;
                                };

                                return (
                                    <Input value={new Intl.NumberFormat().format(resolution.width * resolution.height)} disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                </Row>
            </Card>

            <Card title="Cabinet" size="small" style={{ marginBottom: '16px' }}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            label="Modules Width"
                            name={['cabinet', 'modules', 'width']}
                            extra="The number of modules in the width of the cabinet."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={0}
                                inputMode="numeric"
                                suffix="modules"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Modules Height"
                            name={['cabinet', 'modules', 'height']}
                            extra="The number of modules in the height of the cabinet."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={0}
                                inputMode="numeric"
                                suffix="modules"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Modules Total"
                            extra="The total number of modules in the cabinet."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinet?.modules !== c?.cabinet?.modules}
                        >
                            {({ getFieldValue }) => {
                                const modules = getFieldValue(['cabinet', 'modules']);
                                if (!modules) {
                                    return <Input disabled />;
                                };

                                return (
                                    <Input value={new Intl.NumberFormat().format(modules.width * modules.height)} disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Physical Resolution"
                            extra="The resolution of the cabinet in pixels."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.module?.resolution !== c?.module?.resolution || p?.cabinet?.modules !== c?.cabinet?.modules}
                        >
                            {({ getFieldValue }) => {
                                const resolution = getFieldValue(['module', 'resolution']);
                                const modules = getFieldValue(['cabinet', 'modules']);
                                if (!resolution || !modules) {
                                    return <Input suffix="pixels" disabled />;
                                };

                                const width = resolution.width * modules.width;
                                const height = resolution.height * modules.height;

                                return (
                                    <Input value={`${ width } x ${ height }`} suffix="pixels" disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            label="Physical Pixels"
                            extra="The total number of pixels in the cabinet."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.module?.resolution !== c?.module?.resolution || p?.cabinet?.modules !== c?.cabinet?.modules}
                        >
                            {({ getFieldValue }) => {
                                const resolution = getFieldValue(['module', 'resolution']);
                                const modules = getFieldValue(['cabinet', 'modules']);
                                if (!resolution || !modules) {
                                    return <Input suffix="pixels" disabled />;
                                };

                                const width = resolution.width * modules.width;
                                const height = resolution.height * modules.height;

                                return (
                                    <Input value={new Intl.NumberFormat().format(width * height)} suffix="pixels" disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Weight"
                            name={['cabinet', 'weight']}
                            extra="The weight of the cabinet in kilograms."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={2}
                                suffix="kg"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Power Max"
                            name={['cabinet', 'power', 'max']}
                            extra="The maximum power consumption of the cabinet in watts."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={0}
                                suffix="watts"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>

            <Card title="Screen" size="small" style={{ marginBottom: '16px' }}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            label="Cabinets Width"
                            name={['screen', 'cabinets', 'width']}
                            extra="The number of cabinets in the width of the screen."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={0}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Cabinets Height"
                            name={['screen', 'cabinets', 'height']}
                            extra="The number of cabinets in the height of the screen."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={0}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Cabinets Total"
                            extra="The total number of cabinets in the screen."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.screen?.cabinets !== c?.screen?.cabinets}
                        >
                            {({ getFieldValue }) => {
                                const cabinets = getFieldValue(['screen', 'cabinets']);
                                if (!cabinets) {
                                    return <Input disabled />;
                                };

                                return (
                                    <Input value={new Intl.NumberFormat().format(cabinets.width * cabinets.height)} disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Screen Area</Divider>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            label="Screen Area Width"
                            extra="The width of the screen in meters."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.module?.width !== c?.module?.width || p?.cabinet?.modules !== c?.cabinet?.modules || p?.screen?.cabinets !== c?.screen?.cabinets}
                        >
                            {({ getFieldValue }) => {
                                const moduleWidth = getFieldValue(['module', 'width']);
                                const modulesWidth = getFieldValue(['cabinet', 'modules', 'width']);
                                const cabinetsWidth = getFieldValue(['screen', 'cabinets', 'width']);
                                if (!moduleWidth || !modulesWidth || !cabinetsWidth) {
                                    return <Input suffix="m" disabled />;
                                };

                                const width = moduleWidth * modulesWidth * cabinetsWidth / 1000;

                                return (
                                    <Input value={width} suffix="m" disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Screen Area Height"
                            extra="The height of the screen in meters."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.module?.height !== c?.module?.height || p?.cabinet?.modules !== c?.cabinet?.modules || p?.screen?.cabinets !== c?.screen?.cabinets}
                        >
                            {({ getFieldValue }) => {
                                const moduleHeight = getFieldValue(['module', 'height']);
                                const modulesHeight = getFieldValue(['cabinet', 'modules', 'height']);
                                const cabinetsHeight = getFieldValue(['screen', 'cabinets', 'height']);
                                if (!moduleHeight || !modulesHeight || !cabinetsHeight) {
                                    return <Input suffix="m" disabled />;
                                };

                                const height = moduleHeight * modulesHeight * cabinetsHeight / 1000;

                                return (
                                    <Input value={height} suffix="m" disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Screen Area"
                            extra="The total area of the screen in square meters."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.module?.width !== c?.module?.width || p?.module?.height !== c?.module?.height || p?.cabinet?.modules !== c?.cabinet?.modules || p?.screen?.cabinets !== c?.screen?.cabinets}
                        >
                            {({ getFieldValue }) => {
                                const moduleWidth = getFieldValue(['module', 'width']);
                                const moduleHeight = getFieldValue(['module', 'height']);
                                const modulesWidth = getFieldValue(['cabinet', 'modules', 'width']);
                                const modulesHeight = getFieldValue(['cabinet', 'modules', 'height']);
                                const cabinetsWidth = getFieldValue(['screen', 'cabinets', 'width']);
                                const cabinetsHeight = getFieldValue(['screen', 'cabinets', 'height']);
                                if (!moduleWidth || !moduleHeight || !modulesWidth || !modulesHeight || !cabinetsWidth || !cabinetsHeight) {
                                    return <Input suffix="m²" disabled />;
                                };

                                const width = moduleWidth * modulesWidth * cabinetsWidth / 1000;
                                const height = moduleHeight * modulesHeight * cabinetsHeight / 1000;

                                return (
                                    <Input value={new Intl.NumberFormat().format(width * height)} suffix="m²" disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Screen Resolution</Divider>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            label="Physical Resolution"
                            extra="The resolution of the screen in pixels."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.module?.resolution !== c?.module?.resolution || p?.cabinet?.modules !== c?.cabinet?.modules || p?.screen?.cabinets !== c?.screen?.cabinets}
                        >
                            {({ getFieldValue }) => {
                                const resolution = getFieldValue(['module', 'resolution']);
                                const modules = getFieldValue(['cabinet', 'modules']);
                                const cabinets = getFieldValue(['screen', 'cabinets']);
                                if (!resolution || !modules || !cabinets) {
                                    return <Input suffix="pixels" disabled />;
                                };

                                const width = resolution.width * modules.width * cabinets.width;
                                const height = resolution.height * modules.height * cabinets.height;

                                return (
                                    <Input value={`${ width } x ${ height }`} suffix="pixels" disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Physical Pixels"
                            extra="The total number of pixels in the screen."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.module?.resolution !== c?.module?.resolution || p?.cabinet?.modules !== c?.cabinet?.modules || p?.screen?.cabinets !== c?.screen?.cabinets}
                        >
                            {({ getFieldValue }) => {
                                const resolution = getFieldValue(['module', 'resolution']);
                                const modules = getFieldValue(['cabinet', 'modules']);
                                const cabinets = getFieldValue(['screen', 'cabinets']);
                                if (!resolution || !modules || !cabinets) {
                                    return <Input suffix="pixels" disabled />;
                                };

                                const width = resolution.width * modules.width * cabinets.width;
                                const height = resolution.height * modules.height * cabinets.height;

                                return (
                                    <Input value={new Intl.NumberFormat().format(width * height)} suffix="pixels" disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Screen Ratio"
                            extra="The ratio of the screen resolution."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.module?.resolution !== c?.module?.resolution || p?.cabinet?.modules !== c?.cabinet?.modules || p?.screen?.cabinets !== c?.screen?.cabinets}
                        >
                            {({ getFieldValue }) => {
                                const resolution = getFieldValue(['module', 'resolution']);
                                const modules = getFieldValue(['cabinet', 'modules']);
                                const cabinets = getFieldValue(['screen', 'cabinets']);
                                if (!resolution || !modules || !cabinets) {
                                    return <Input disabled />;
                                };

                                const width = resolution.width * modules.width * cabinets.width;
                                const height = resolution.height * modules.height * cabinets.height;

                                return (
                                    <Input value={width / height} disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Aspect Ratio"
                            extra="The aspect ratio of the screen resolution."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.module?.resolution !== c?.module?.resolution || p?.cabinet?.modules !== c?.cabinet?.modules || p?.screen?.cabinets !== c?.screen?.cabinets}
                        >
                            {({ getFieldValue }) => {
                                const resolution = getFieldValue(['module', 'resolution']);
                                const modules = getFieldValue(['cabinet', 'modules']);
                                const cabinets = getFieldValue(['screen', 'cabinets']);
                                if (!resolution || !modules || !cabinets || !cabinets.width || !cabinets.height) {
                                    return <Input disabled />;
                                };

                                const width = resolution.width * modules.width * cabinets.width;
                                const height = resolution.height * modules.height * cabinets.height;
                                const r = gcd(width, height);

                                return (
                                    <Input value={`${ width / r }:${ height / r}`} disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Screen Properties (weight and power)</Divider>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            label="Weight"
                            extra="The total weight of the screen in kilograms."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinet?.weight !== c?.cabinet?.weight || p?.screen?.cabinets !== c?.screen?.cabinets}
                        >
                            {({ getFieldValue }) => {
                                const weight = getFieldValue(['cabinet', 'weight']);
                                const cabinets = getFieldValue(['screen', 'cabinets']);
                                if (!weight || !cabinets) {
                                    return <Input suffix="kg" disabled />;
                                };

                                return (
                                    <Input value={new Intl.NumberFormat().format(weight * cabinets.width * cabinets.height)} suffix="kg" disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Power Max"
                            extra="The maximum power consumption of the screen in watts."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinet?.power?.max !== c?.cabinet?.power?.max || p?.screen?.cabinets !== c?.screen?.cabinets}
                        >
                            {({ getFieldValue }) => {
                                const power = getFieldValue(['cabinet', 'power', 'max']);
                                const cabinets = getFieldValue(['screen', 'cabinets']);
                                if (!power || !cabinets) {
                                    return <Input suffix="watts" disabled />;
                                };

                                return (
                                    <Input value={new Intl.NumberFormat().format(power * cabinets.width * cabinets.height)} suffix="watts" disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Power Max (Amps)"
                            extra="The maximum power consumption of the screen in amps."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinet?.power?.max !== c?.cabinet?.power?.max || p?.screen?.cabinets !== c?.screen?.cabinets}
                        >
                            {({ getFieldValue }) => {
                                const power = getFieldValue(['cabinet', 'power', 'max']);
                                const cabinets = getFieldValue(['screen', 'cabinets']);
                                if (!power || !cabinets) {
                                    return <Input suffix="amps" disabled />;
                                };

                                return (
                                    <Input value={new Intl.NumberFormat().format(power * cabinets.width * cabinets.height / 120)} suffix="amps" disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Required Circuits"
                            extra="The number of required 20 amp circuits."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinet?.power?.max !== c?.cabinet?.power?.max || p?.screen?.cabinets !== c?.screen?.cabinets}
                        >
                            {({ getFieldValue }) => {
                                const power = getFieldValue(['cabinet', 'power', 'max']);
                                const cabinets = getFieldValue(['screen', 'cabinets']);
                                if (!power || !cabinets) {
                                    return <Input disabled />;
                                };

                                const amps = power * cabinets.width * cabinets.height / 120;

                                return (
                                    <Input value={new Intl.NumberFormat().format(amps / 20)} disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                </Row>
            </Card>

            <Card title="Pricing & Requirements" size="small" style={{ marginBottom: '16px' }}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            label="Price per Square Meter"
                            name={['pricing', 'perSqMeter']}
                            extra="The price per square meter of the screen."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={2}
                                prefix="$"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Total Price"
                            extra="The total price of the screen."
                            shouldUpdate={(p: IFormValues, c: IFormValues) => p?.pricing?.perSqMeter !== c?.pricing?.perSqMeter || p?.module?.width !== c?.module?.width || p?.module?.height !== c?.module?.height || p?.cabinet?.modules !== c?.cabinet?.modules || p?.screen?.cabinets !== c?.screen?.cabinets}
                        >
                            {({ getFieldValue }) => {
                                const moduleWidth = getFieldValue(['module', 'width']);
                                const moduleHeight = getFieldValue(['module', 'height']);
                                const modulesWidth = getFieldValue(['cabinet', 'modules', 'width']);
                                const modulesHeight = getFieldValue(['cabinet', 'modules', 'height']);
                                const cabinetsWidth = getFieldValue(['screen', 'cabinets', 'width']);
                                const cabinetsHeight = getFieldValue(['screen', 'cabinets', 'height']);
                                const price = getFieldValue(['pricing', 'perSqMeter']);
                                if (!price || !moduleWidth || !moduleHeight || !modulesWidth || !modulesHeight || !cabinetsWidth || !cabinetsHeight) {
                                    return <Input prefix="$" disabled />;
                                };

                                const width = moduleWidth * modulesWidth * cabinetsWidth / 1000;
                                const height = moduleHeight * modulesHeight * cabinetsHeight / 1000;

                                return (
                                    <Input value={new Intl.NumberFormat().format(price * width * height)} prefix="$" disabled />
                                );
                            }}
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
    );
}
