import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { persist, createJSONStorage } from 'zustand/middleware'
import type { } from '@redux-devtools/extension'; // required for devtools typing

import type { ICabinet } from 'models/cabinet';
import type { IQuote } from 'models/quote';

interface CabinetState {
    cabinets: ICabinet[];
    selectedCabinet?: ICabinet;

    setCabinets: (cabinets: ICabinet[]) => void;
    addCabinet: (cabinet: ICabinet) => void;
    setSelectedCabinet: (cabinet: ICabinet) => void;
    clearSelectedCabinet: () => void;
}

export const useCabinetStore = create<CabinetState>()(
    devtools(
        persist(
            (set) => ({
                cabinets: [],
                selectedCabinet: undefined,

                setCabinets: (cabinets) => set({ cabinets }),
                addCabinet: (cabinet) => set((state) => ({ cabinets: [...state.cabinets, cabinet] })),
                setSelectedCabinet: (selectedCabinet) => set({ selectedCabinet }),
                clearSelectedCabinet: () => set({ selectedCabinet: undefined }),
            }),
            {
                name: 'cabinet-store',
                storage: createJSONStorage(() => localStorage),
            },
        ),
    ),
);

interface QuoteState {
    quote?: IQuote;
    requestedWidth?: number;
    requestedHeight?: number;

    setQuote: (quote: IQuote) => void;
    setRequestedWidth: (requestedWidth: number) => void;
    setRequestedHeight: (requestedHeight: number) => void;
    clearQuote: () => void;
}

export const useQuoteStore = create<QuoteState>()(
    devtools(
        persist(
            (set) => ({
                quote: undefined,

                setQuote: (quote) => set({ quote }),
                setRequestedWidth: (requestedWidth) => set({ requestedWidth }),
                setRequestedHeight: (requestedHeight) => set({ requestedHeight }),
                clearQuote: () => set({ quote: undefined }),
            }),
            {
                name: 'quote-store',
                storage: createJSONStorage(() => localStorage),
            },
        ),
    ),
);
