import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import './index.css';
import reportWebVitals from './reportWebVitals';

import { Root } from 'components/layout';
import { Cabinets } from 'views/cabinets';
import { CostCalculator } from 'views/cost';
import { Builder } from 'views/builder';
import { Calculator } from 'views/calculator';
import { Misc } from 'views/misc';
import { Quote } from 'views/quote';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "/",
                element: <Cabinets />,
            },
            {
                path: "/cost",
                element: <CostCalculator />,
            },
            {
                path: "/builder",
                element: <Builder />,
            },
            {
                path: "/calculator",
                element: <Calculator />,
            },
            {
                path: "/misc",
                element: <Misc />,
            },
        ],
    },
    {
        path: "/quote",
        element: <Quote />,
    },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
