import { ICalculatorRequest, ICalculatorResponse } from "models/calculator";
import { novaStars } from "models/novastar";

import { gcd } from "./";
import { IQuoteLineItems } from "models/quote";

export const calculator = (req: ICalculatorRequest): ICalculatorResponse => {
    let markup = 2;
    if (req.markup && req.markup > 0) {
        markup = req.markup;
    }

    const { cabinet, width, height } = req;
    const widthMeters = width * 0.3048 * 1000;
    const heightMeters = height * 0.3048 * 1000;

    const widthCabinets = Math.round(widthMeters / (cabinet.module.width * cabinet.modules.width));
    const heightCabinets = Math.round(heightMeters / (cabinet.module.height * cabinet.modules.height));

    const weightLbs = cabinet.weight * widthCabinets * heightCabinets * 2.20462;
    const actualWidthFt = (widthCabinets * cabinet.module.width * cabinet.modules.width / 1000 * 3.28084).toFixed(2);
    const actualHeightFt = (heightCabinets * cabinet.module.height * cabinet.modules.height / 1000 * 3.28084).toFixed(2);

    const resolutionWidth = cabinet.module.resolution.width * cabinet.modules.width * widthCabinets;
    const resolutionHeight = cabinet.module.resolution.height * cabinet.modules.height * heightCabinets;
    const physicalPixels = resolutionWidth * resolutionHeight;

    const novastar = novaStars.find((n) => physicalPixels <= n.max.pixels);

    let r = -1;
    if (resolutionWidth && resolutionHeight) {
        r = gcd(resolutionWidth, resolutionHeight);
    }

    const maxPowerWatts = cabinet.maxPower * widthCabinets * heightCabinets;
    const maxPowerAmps = maxPowerWatts / 110;
    const twentyAmps = Math.ceil(maxPowerAmps / 20);
    const fifteenAmps = Math.ceil(maxPowerAmps / 15);

    const aspectRatio = r > 0 ? `${resolutionWidth / r}:${resolutionHeight / r}` : 'N/A';

    const cabinetsCost = parseFloat(cabinet.cost) * widthCabinets * heightCabinets;
    const cabinetsPrice = cabinetsCost * markup;
    let originalPrice = (cabinetsCost * markup) + (novastar?.price || 0) + req.items.reduce((acc, i) => acc + (i.quantity * i.unitPrice), 0);
    let price = (cabinetsCost * markup) + (novastar?.price || 0) + req.items.reduce((acc, i) => acc + (i.quantity * i.unitPrice), 0);

    if (req.discount?.amount && req.discount?.amount > 0) {
        price -= req.discount.amount;
    }

    if (req.discount?.percent && req.discount?.percent > 0) {
        price -= price * req.discount.percent / 100;
    }

    let discountedAmount = 0;
    if (price < originalPrice) {
        discountedAmount = originalPrice - price;
    }

    const nf = new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const nf0 = new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });

    const items: IQuoteLineItems[] = [
        {
            description: cabinet.invoiceDescription || cabinet.name,
            quantity: widthCabinets * heightCabinets,
            unitPrice: parseFloat(cabinet.cost) * markup,
            total: cabinetsPrice,
        },
        {
            description: 'NovaStar Processor',
            quantity: 1,
            unitPrice: novastar?.price || 0,
            total: novastar?.price || 0,
        },
        ...req.items.map((i) => ({
            description: i.description,
            quantity: i.quantity,
            unitPrice: i.unitPrice,
            total: i.quantity * i.unitPrice,
        })),
    ];

    return {
        dimensionsInMeters: {
            width: nf.format(widthMeters),
            height: nf.format(heightMeters),
        },
        squareMeters: nf.format(widthMeters * heightMeters),
        dimensionsInFeet: {
            width: actualWidthFt,
            height: actualHeightFt,
        },
        squareFeet: nf.format(width * height),
        cabinets: {
            width: nf0.format(widthCabinets),
            height: nf0.format(heightCabinets),
        },
        totalCabinets: widthCabinets * heightCabinets,
        resolution: {
            width: nf0.format(resolutionWidth),
            height: nf0.format(resolutionHeight),
        },
        resolutionFormatted: `${resolutionWidth} x ${resolutionHeight}`,
        pixels: nf0.format(physicalPixels),
        aspectRatio: {
            width: (resolutionWidth / r).toFixed(2),
            height: (resolutionHeight / r).toFixed(2),
        },
        aspectRatioFormatted: aspectRatio,
        cost: nf.format(cabinetsCost),
        cabinetsPrice: nf.format(cabinetsPrice),
        originalPrice: nf.format(originalPrice),
        price: nf.format(price),
        cabinet,
        cabinetPrice: nf.format(parseFloat(cabinet.cost) * 2),
        novastar,
        discount: req.discount,
        discountAmount: discountedAmount > 0 ? nf.format(discountedAmount) : undefined,
        weight: {
            kg: nf.format(cabinet.weight * widthCabinets * heightCabinets),
            lb: nf.format(Math.ceil(weightLbs)),
        },
        power: {
            watts: nf0.format(maxPowerWatts),
            amps: {
                total: nf.format(maxPowerAmps),
                twenty: nf.format(twentyAmps),
                fifteen: nf.format(fifteenAmps),
            },
        },
        items,
    };
};
