import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuoteStore } from 'store';
import "./quote.css";

export const Quote: React.FC = () => {
    const navigate = useNavigate();
    const { quote } = useQuoteStore((state) => state);

    useEffect(() => {
        if (!quote || !quote.result) {
            navigate('/');
        }
    }, [navigate, quote]);

    const date = useMemo(() => {
        const now = new Date();

        return `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`;
    }, []);

    const nf = useMemo(() => new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }), []);

    const lineItems = useMemo(() => {
        if (!quote || !quote.result || !Array.isArray(quote.result.items) || quote.result.items.length === 0) {
            return null;
        }

        return quote.result.items.map((item, index) => (
            <div key={index} className="flex-1 flex text-base">
                <div className="flex-1 text-center">{item.quantity}</div>
                <div className="flex-auto w-64 text-center">{item.description}</div>
                <div className="flex-1 text-center">${nf.format(item.unitPrice)}</div>
                <div className="flex-1 text-center">${nf.format(item.total)}</div>
            </div>
        ));
    }, [nf, quote]);

    return (
        <div id="quote" className="bg-white">
            <div className="max-w-5xl mx-auto bg-white page-border-purple p-0">
                <div className="text-center mb-1">
                    <h1 className="text-sm font-bold">LED Video Wall Quote</h1>
                </div>
                <div className="bg-custom-purple h-4 mb-3"></div>
                <div className="max-w-md mx-auto">
                    <div className="border-right-2 border-custom-purple text-center mb-4">
                        <div className="grid grid-cols-2">
                            <div className="font-bold text-sm text-end text-black pr-2">
                                Prepared For:
                            </div>
                            <div className="bg-custom-blue text-sm border border-custom-purple text-black font-bold">
                                {quote?.requestor.name}
                            </div>
                            <div className="font-bold text-sm text-end text-black pr-2">
                                Prepared By:
                            </div>
                            <div className="bg-custom-blue border text-sm border-custom-purple text-black font-bold">
                                {quote?.author.name}
                            </div>
                            <div className="font-bold text-sm text-end text-black pr-2">
                                Date Created:
                            </div>
                            <div className="border border-custom-purple text-black text-sm font-bold">
                                {date}
                            </div>
                            <div className="font-bold text-sm text-end text-black pr-2">
                                Quote Number:
                            </div>
                            <div className="border border-custom-purple text-sm text-black font-bold">
                                {quote?.requestor.name} {date}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-custom-purple h-4 mb-3"></div>

                <div className="max-w-lg mx-auto">
                    <div className="flex justify-between items-center">
                        <div className="text-right mr-8">
                            <div className="font-bold text-black text-sm mb-2">
                                Requested Width
                            </div>
                            <div className="font-bold text-black text-sm">
                                Requested Height
                            </div>
                        </div>

                        <div className="inline-block w-64">
                            <div className="border border-custom-purple flex items-center">
                                <span className="flex-1 font-bold text-black text-sm text-right pr-2">{Math.round(quote?.requestedWidth || 0)}</span>
                                <span className="flex-1 border-l border-custom-purple h-full pl-2">Ft</span>
                            </div>
                            <div className="border-t-0 border-l border-r border-b border-custom-purple flex items-center">
                                <span className="flex-1 font-bold text-black text-sm text-right pr-2">{Math.round(quote?.requestedHeight || 0)}</span>
                                <span className="flex-1 border-l border-custom-purple h-full pl-2">Ft</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-custom-purple h-4 mb-2 mt-2"></div>

                <div className="max-w-4xl mx-auto">
                    <div className="text-left font-bold text-lg mb-3">
                        Specifications - Physical
                    </div>
                    <div className="grid grid-cols-4  gap-2 items-center">
                        <div className="space-y-2">
                            <div className="font-bold text-sm">Cabinets Width</div>
                            <div className="font-bold text-sm">Cabinets Height</div>
                            <div className="font-bold text-sm">Total Cabinets</div>
                            <div className="font-bold text-sm">Wall Weight</div>
                        </div>

                        <div>
                            <table className="border-collapse border border-custom-purple w-40">
                                <tr>
                                    <td className="border text-center border-custom-purple">{quote?.result?.cabinets.width}</td>
                                    <td className="border border-custom-purple text-start">Panels</td>
                                </tr>
                                <tr>
                                    <td className="border text-center border-custom-purple">{quote?.result?.cabinets.height}</td>
                                    <td className="border border-custom-purple text-start">Panels</td>
                                </tr>
                                <tr>
                                    <td className="border text-center border-custom-purple">{quote?.result?.totalCabinets}</td>
                                    <td className="border border-custom-purple text-start">Panels</td>
                                </tr>
                                <tr>
                                    <td className="border text-center border-custom-purple">{quote?.result?.weight.lb}</td>
                                    <td className="border border-custom-purple text-start">Lbs</td>
                                </tr>
                            </table>
                        </div>
                        <div className="space-y-2">
                            <div className="font-bold text-sm">Actual Width</div>
                            <div className="font-bold text-sm">Actual Height</div>
                            <div className="font-bold text-sm">Actual Diagonal</div>
                            <div className="font-bold text-sm">Screen Area</div>
                        </div>

                        <div>
                            <table className="border-collapse w-40">
                                <tr>
                                    <td className="border border-custom-purple bg-custom-blue text-end pr-1">{quote?.result?.dimensionsInFeet.width}</td>
                                    <td className="border border-custom-purple bg-custom-blue text-start pl-1">Ft</td>
                                </tr>
                                <tr>
                                    <td className="border border-custom-purple bg-custom-blue text-end pr-1">{quote?.result?.dimensionsInFeet.height}</td>
                                    <td className="border border-custom-purple bg-custom-blue text-start pl-1">Ft</td>
                                </tr>
                                <tr>
                                    <td className="border border-custom-purple text-end pr-1">9.52</td>
                                    <td className="border border-custom-purple text-start pl-1">Ft</td>
                                </tr>
                                <tr>
                                    <td className="border border-custom-purple text-end pr-1">{quote?.result?.squareFeet}</td>
                                    <td className="border border-custom-purple text-start pl-1">Sq Ft</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="bg-custom-purple h-4 mb-2 mt-2"></div>

                <div className="max-w-4xl mx-auto">
                    <div className="text-left font-bold text-lg mb-3">
                        Specifications - Video/Electrical
                    </div>

                    <div className="grid grid-cols-4  gap-4 items-center">
                        <div>
                            <div className="font-bold text-sm">Max Power (W)</div>
                            <div className="font-bold text-sm">
                                Circuits (20A - 110V)
                            </div>
                            <div className="font-bold text-sm">
                                Circuits (15A - 110V)
                            </div>
                        </div>

                        <div>
                            <table className="border-collapse border border-custom-purple w-32">
                                <tr>
                                    <td className="border text-center border-custom-purple">{quote?.result?.power.watts}</td>
                                </tr>
                                <tr>
                                    <td className="border text-center border-custom-purple">{quote?.result?.power.amps.twenty}</td>
                                </tr>
                                <tr>
                                    <td className="border text-center border-custom-purple">{quote?.result?.power.amps.fifteen}</td>
                                </tr>
                            </table>
                        </div>
                        <div className="space-y-2">
                            <div className="font-bold text-sm">Total Pixels</div>
                            <div className="font-bold text-sm">Controllers Needed</div>
                            <div className="font-bold text-sm">Panel Refresh Rate</div>
                        </div>

                        <div>
                            <table className="border-collapse w-32">
                                <tr>
                                    <td className="border text-center border-custom-purple">{quote?.result?.pixels}</td>
                                </tr>
                                <tr>
                                    <td className="border text-center border-custom-purple">1</td>
                                </tr>
                                <tr>
                                    <td className="border text-center border-custom-purple">≥3,840 HZ</td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div className="flex justify-center p-2 relative">
                        <div className="space-y-2 mt-5">
                            <div className="font-bold text-sm">Native Resolution</div>
                            <div className="font-bold text-sm">Aspect Ratio</div>
                        </div>
                        <table className="min-w-[300px]  border-collapse">
                            <thead>
                                <tr>
                                    <th className="text-center text-sm">Width</th>
                                    <th className="text-center text-sm"></th>
                                    <th className="text-center text-sm">Height</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border text-center table-border-purple">{quote?.result?.resolution.width}</td>
                                    <td className="border text-center table-border-purple">x</td>
                                    <td className="border text-center table-border-purple">{quote?.result?.resolution.height}</td>
                                </tr>
                                <tr>
                                    <td className="border text-center table-border-purple">{quote?.result?.aspectRatio.width}</td>
                                    <td className="border text-center table-border-purple">x</td>
                                    <td className="border text-center table-border-purple">{quote?.result?.aspectRatio.height}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="bg-custom-purple h-4 mt-3"></div>

                <div className="w-full flex flex-col">
                    <div className="flex-1 flex text-lg font-bold border-b border-dashed border-custom-purple">
                        <div className="flex-1 text-center">Qty</div>
                        <div className="flex-auto w-64 text-center">Description</div>
                        <div className="flex-1 text-center">Cost</div>
                        <div className="flex-1 text-center">Subtotal</div>
                    </div>
                    {lineItems}
                    <div className="flex-1 flex text-base">
                        <div className="flex-1 text-center">1</div>
                        <div className="flex-auto w-64 text-center">Free Shipping</div>
                        <div className="flex-1 text-center">$0.00</div>
                        <div className="flex-1 text-center">$0.00</div>
                    </div>
                    {quote?.result?.discountAmount ?
                        <React.Fragment>
                            <div className="flex-1 flex text-base bg-header-gray">
                                <div className="flex-1 text-center"></div>
                                <div className="flex-auto w-64 text-center"></div>
                                <div className="flex-1 text-center font-bold">Subtotal</div>
                                <div className="flex-1 text-center bg-header-white">${quote?.result?.originalPrice}</div>
                            </div>
                            <div className="flex-1 flex text-base bg-header-gray">
                                <div className="flex-1 text-center bg-header-gray"></div>
                                <div className="flex-auto w-64 text-center bg-header-gray"></div>
                                <div className="flex-1 text-center bg-header-gray font-bold">Discount</div>
                                <div className="flex-1 text-center bg-header-white">(${quote?.result?.discountAmount})</div>
                            </div>
                        </React.Fragment>
                        : null}
                    <div className="flex-1 flex text-base bg-header-gray">
                        <div className="flex-1 text-center"></div>
                        <div className="flex-auto w-64 text-center"></div>
                        <div className="flex-1 text-center font-bold">Total</div>
                        <div className="flex-1 text-center bg-header-white border-t border-custom-white">${quote?.result?.price}</div>
                    </div>
                </div>
            </div>
            <div className="max-w-5xl mx-auto mt-3 flex justify-between text-sm text-gray-700">
                <p className="text-left">
                    <span>** Note: Quote valid for 30 days from date listed above in Date Created area.</span><br />
                    <span>** Electrical: Customer ({quote?.requestor.name}) is responsible for all electrical work.</span><br />
                    <span>** Warranty: 3 Years</span>
                </p>
                <p className="text-right">
                    <span>Bradley Hilton</span><br />
                    <span>PO Box 70, Eads, TN 38133</span><br />
                    <span>E: bradley@fidetech.io</span>
                </p>
            </div>
        </div>
    );
}
