import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, Table, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import type { ICabinet } from 'models/cabinet';

import { useCabinetStore } from 'store';
import { AddCabinetModal } from 'components/addCabinet';
import { BuildQuoteButton } from 'components/generateQuote';

export const Cabinets: React.FC = () => {
    const navigate = useNavigate();
    const { cabinets, addCabinet, setSelectedCabinet } = useCabinetStore((state) => state);
    const [addOpen, setAddOpen] = useState(false);

    const onCostClick = useCallback((cabinet: ICabinet) => {
        setSelectedCabinet(cabinet);
        setTimeout(() => navigate('/cost'), 10);
    }, [navigate, setSelectedCabinet]);

    const onBuildClick = useCallback((cabinet: ICabinet) => {
        setSelectedCabinet(cabinet);
        setTimeout(() => navigate('/builder'), 10);
    }, [navigate, setSelectedCabinet]);

    const columns: ColumnsType<ICabinet> = useMemo(() => {
        return [
            { title: 'Name', dataIndex: 'name', key: 'name' },
            { title: 'Description', dataIndex: 'description', key: 'description' },
            { title: 'Invoice Description', dataIndex: 'invoiceDescription', key: 'invoiceDescription', render: (desc) => desc || '-' },
            { title: 'Type', dataIndex: 'type', key: 'type', className: 'title-caps', render: (type: string) => type ? type.replace('-', ' ') : '-' },
            { title: 'Cost', dataIndex: 'cost', key: 'cost', render: (cost) => `$${cost || '-'}` },
            { title: 'Pixel Pitch', dataIndex: ['module', 'pixelPitch'], key: 'pixelPitch' },
            {
                title: 'Dimension', key: 'width',
                render: (_, record) => {
                    const width = record.module.width * record.modules.width;
                    const height = record.module.height * record.modules.height;

                    return `${width}mm x ${height}mm`;
                },
            },
            {
                title: 'Action', key: 'action',
                render: (_, record) => (
                    <Space.Compact block>
                        <Button onClick={() => onCostClick(record)}>Cost of a Screen</Button>
                        <Button onClick={() => onBuildClick(record)}>Build a Screen</Button>
                    </Space.Compact>
                ),
            },
        ];
    }, [onBuildClick, onCostClick]);

    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
                <Typography.Title level={3}>Cabinets</Typography.Title>
                <Space.Compact>
                    <BuildQuoteButton />
                    <Button type="default" onClick={() => setAddOpen(true)}>Add Cabinet</Button>
                </Space.Compact>
            </div>

            <Table<ICabinet>
                columns={columns}
                dataSource={cabinets}
                rowKey="id"
                locale={{
                    emptyText: 'No cabinets created yet',
                }}
                pagination={{
                    showTotal: (total) => `Total cabinets: ${total}`,
                }}
            />

            <AddCabinetModal
                open={addOpen}
                onAdd={(c) => {
                    addCabinet(c);
                    setAddOpen(false);
                }}
                onClose={() => setAddOpen(false)}
            />
        </React.Fragment>
    );
}
