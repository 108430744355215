import React, { useState } from 'react';
import { Button, Input, Typography } from 'antd';

import { useCabinetStore } from 'store';

export const Misc: React.FC = () => {
    const [toSetValue, setToSetValue] = useState<string>('');
    const { cabinets, setCabinets } = useCabinetStore((state) => state);

    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
                <Typography.Title level={3}>Miscellaneous</Typography.Title>
                <Typography.Text>Import and export the cabinets.</Typography.Text>
            </div>

            <div>
                <Typography.Title level={4}>Import</Typography.Title>
                <Typography.Text>Import JSON to set the cabinets.</Typography.Text>

                <Input.TextArea rows={10} onChange={(v) => setToSetValue(v.target.value)} />
                <Button onClick={() => setCabinets(JSON.parse(toSetValue))}>Set Cabinets</Button>
            </div>

            <div>
                <Typography.Title level={4}>Export</Typography.Title>
                <Typography.Text>Export the cabinets as JSON.</Typography.Text><br />

                <Typography.Text code copyable>{ JSON.stringify(cabinets) }</Typography.Text>
            </div>
        </React.Fragment>
    );
};
