import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form, Input, InputNumber, Row, Col, Select } from 'antd';

import type { IQuote } from 'models/quote';

import { useCabinetStore, useQuoteStore } from 'store';
import { calculator } from 'utils/calculator';

export const BuildQuoteButton: React.FC = () => {
    const navigate = useNavigate();
    const { quote, requestedWidth, requestedHeight, setRequestedWidth, setRequestedHeight, setQuote, clearQuote } = useQuoteStore((state) => state);
    const { selectedCabinet, cabinets } = useCabinetStore((state) => state);
    const [form] = Form.useForm<IQuote>();
    const [open, setOpen] = useState(false);

    const onCancelClick = () => {
        setOpen(false);
        form.resetFields();
        clearQuote();
    };

    const onGenerateQuote = async () => {
        try {
            const values = await form.validateFields();
            console.log('Generated quote', values);

            const cabinet = cabinets.find((c) => c.id === values.cabinetId);

            const result = calculator({ markup: values.markup, cabinet: cabinet!, width: values.requestedWidth, height: values.requestedHeight, discount: values.discount, items: values.items });
            console.log('Calculated quote', result);

            const newQuote: IQuote = {
                ...values,
                result,
            };

            setQuote(newQuote);
            navigate('/quote');
        } catch (error) {
            console.error('Failed to generate quote', error);
        }
    };

    return (
        <React.Fragment>
            <Button type="primary" onClick={() => setOpen(true)}>Generate Quote</Button>

            <Modal
                title="Generate Quote"
                open={open}
                okText="Generate"
                onCancel={onCancelClick}
                onOk={onGenerateQuote}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        markup: 2,
                        ...quote,
                        requestedWidth,
                        requestedHeight,
                        cabinetId: selectedCabinet?.id,
                    }}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Requestor Name" name={['requestor', 'name']} rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Your Name" name={['author', 'name']} rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Requested Width (ft)" name="requestedWidth" rules={[{ required: true }]}>
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    step={0.1}
                                    suffix="feet"
                                    style={{ width: '100%' }}
                                    onChange={(value) => setRequestedWidth(value || 0)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Requested Height (ft)" name="requestedHeight" rules={[{ required: true }]}>
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    step={0.1}
                                    suffix="feet"
                                    style={{ width: '100%' }}
                                    onChange={(value) => setRequestedHeight(value || 0)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Cabinet" name="cabinetId" rules={[{ required: true }]}>
                                <Select style={{ flex: 0.5 }}>
                                    {cabinets.map((cabinet) => (
                                        <Select.Option key={cabinet.id} value={cabinet.id}>
                                            {cabinet.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label="Markup Multiplier" name="markup" rules={[{ required: true, message: 'Required!' }]}>
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    step={0.01}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Discount Percent" name={['discount', 'percent']}>
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    step={0.01}
                                    suffix="%"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Discount Amount" name={['discount', 'amount']}>
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    step={0.01}
                                    prefix="$"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* TODO: add the ability for creating separate line items. Take the quantity, description and cost */}
                    <Form.Item label="Additional Invoice Line Items">
                        <Form.List name="items">
                            {(fields, { add, remove }) => (
                                <React.Fragment>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Row key={key} gutter={16}>
                                            <Col span={5}>
                                                <Form.Item
                                                    {...restField}
                                                    label="Quantity"
                                                    name={[name, 'quantity']}
                                                    rules={[{ required: true, message: 'Missing quantity' }]}
                                                >
                                                    <InputNumber
                                                        min={0}
                                                        step={1}
                                                        precision={0}
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item
                                                    {...restField}
                                                    label="Description"
                                                    name={[name, 'description']}
                                                    rules={[{ required: true, message: 'Missing description' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={7}>
                                                <Form.Item
                                                    {...restField}
                                                    label="Cost"
                                                    name={[name, 'unitPrice']}
                                                    rules={[{ required: true, message: 'Missing cost' }]}
                                                >
                                                    <InputNumber
                                                        min={0}
                                                        step={0.01}
                                                        precision={2}
                                                        prefix="$"
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}>
                                                <Form.Item label=" ">
                                                    <Button type="primary" danger size="small" onClick={() => remove(name)}>X</Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block>Add Item</Button>
                                    </Form.Item>
                                </React.Fragment>
                            )}
                        </Form.List>
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    );
};
