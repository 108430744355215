import React, { useCallback, useMemo } from 'react';
import { Card, Col, Divider, Form, Input, InputNumber, Row, Select, Space, Typography } from 'antd';

import { BuildQuoteButton } from 'components/generateQuote';
import { gcd } from 'utils';
import { useCabinetStore } from 'store';

interface IFormValues {
    cabinets: {
        width: number;
        height: number;
    };
}

export const CostCalculator: React.FC = () => {
    const { selectedCabinet, cabinets, setSelectedCabinet } = useCabinetStore((state) => state);
    const [form] = Form.useForm();

    const dimensions = useMemo(() => {
        if (!selectedCabinet) {
            return null;
        }

        const widthInMillimeters = selectedCabinet.module.width * selectedCabinet.modules.width;
        const heightInMillimeters = selectedCabinet.module.height * selectedCabinet.modules.height;

        return `${ widthInMillimeters }mm x ${ heightInMillimeters }mm`;
    }, [selectedCabinet]);

    const onCabinetSelect = useCallback((cabinetId: string) => {
        const cabinet = cabinets.find((cabinet) => cabinet.id === cabinetId);
        if (!cabinet) {
            return;
        }

        setSelectedCabinet(cabinet);
    }, [cabinets, setSelectedCabinet]);

    if (!selectedCabinet) {
        //TODO: enable a selector to choose a cabinet
        return (
            <div>No cabinet selected</div>
        );
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
                <div>
                    <Typography.Title level={3}>Cost Calculator for: { selectedCabinet.name } ({ selectedCabinet.description })</Typography.Title>
                    <Typography.Text type="secondary">${ selectedCabinet.cost } per cabinet. { dimensions }</Typography.Text>
                </div>
                <Space.Compact>
                    <BuildQuoteButton />
                    <Select style={{ flex: 0.5 }} onChange={onCabinetSelect} defaultValue={selectedCabinet?.id || undefined}>
                        { cabinets.map((cabinet) => (
                            <Select.Option key={cabinet.id} value={cabinet.id}>
                                { cabinet.name }
                            </Select.Option>
                        ))}
                    </Select>
                </Space.Compact>
            </div>

            <Form
                form={form}
                layout="vertical"
            >
                <Card title="Screen" size="small" style={{ marginBottom: '16px' }}>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item
                                label="Cabinets Width"
                                name={['cabinets', 'width']}
                                extra="The number of cabinets in the width of the screen."
                                rules={[{ required: true }]}
                            >
                                <InputNumber
                                    precision={0}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Cabinets Height"
                                name={['cabinets', 'height']}
                                extra="The number of cabinets in the height of the screen."
                                rules={[{ required: true }]}
                            >
                                <InputNumber
                                    precision={0}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Cabinets Total"
                                extra="The total number of cabinets in the screen."
                                shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinets !== c?.cabinets}
                            >
                                {({ getFieldValue }) => {
                                    const cabinets = getFieldValue(['cabinets']);
                                    if (!cabinets) {
                                        return <Input disabled />;
                                    };

                                    return (
                                        <Input value={new Intl.NumberFormat().format(cabinets.width * cabinets.height)} disabled />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left">Screen Area</Divider>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item
                                label="Screen Area Width"
                                extra="The width of the screen in meters."
                                shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinets !== c?.cabinets}
                            >
                                {({ getFieldValue }) => {
                                    const moduleWidth = selectedCabinet.module.width;
                                    const modulesWidth = selectedCabinet.modules.width;
                                    const cabinetsWidth = getFieldValue(['cabinets', 'width']);
                                    if (!moduleWidth || !modulesWidth || !cabinetsWidth) {
                                        return <Input suffix="m" disabled />;
                                    };

                                    const width = moduleWidth * modulesWidth * cabinetsWidth / 1000;

                                    return (
                                        <Input value={width} suffix="m" disabled />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Screen Area Height"
                                extra="The height of the screen in meters."
                                shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinets !== c?.cabinets}
                            >
                                {({ getFieldValue }) => {
                                    const moduleHeight = selectedCabinet.module.height;
                                    const modulesHeight = selectedCabinet.modules.height;
                                    const cabinetsHeight = getFieldValue(['cabinets', 'height']);
                                    if (!moduleHeight || !modulesHeight || !cabinetsHeight) {
                                        return <Input suffix="m" disabled />;
                                    };

                                    const height = moduleHeight * modulesHeight * cabinetsHeight / 1000;

                                    return (
                                        <Input value={height} suffix="m" disabled />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Screen Area"
                                extra="The total area of the screen in square meters."
                                shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinets !== c?.cabinets}
                            >
                                {({ getFieldValue }) => {
                                    const moduleWidth = selectedCabinet.module.width
                                    const moduleHeight = selectedCabinet.module.height;
                                    const modulesWidth = selectedCabinet.modules.width;
                                    const modulesHeight = selectedCabinet.modules.height;
                                    const cabinetsWidth = getFieldValue(['cabinets', 'width']);
                                    const cabinetsHeight = getFieldValue(['cabinets', 'height']);
                                    if (!moduleWidth || !moduleHeight || !modulesWidth || !modulesHeight || !cabinetsWidth || !cabinetsHeight) {
                                        return <Input suffix="m²" disabled />;
                                    };

                                    const width = moduleWidth * modulesWidth * cabinetsWidth / 1000;
                                    const height = moduleHeight * modulesHeight * cabinetsHeight / 1000;

                                    return (
                                        <Input value={new Intl.NumberFormat().format(width * height)} suffix="m²" disabled />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left">Screen Resolution</Divider>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item
                                label="Physical Resolution"
                                extra="The resolution of the screen in pixels."
                                shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinets !== c?.cabinets}
                            >
                                {({ getFieldValue }) => {
                                    const resolution = selectedCabinet.module.resolution;
                                    const modules = selectedCabinet.modules;
                                    const cabinets = getFieldValue(['cabinets']);
                                    if (!resolution || !modules || !cabinets) {
                                        return <Input suffix="pixels" disabled />;
                                    };

                                    const width = resolution.width * modules.width * cabinets.width;
                                    const height = resolution.height * modules.height * cabinets.height;

                                    return (
                                        <Input value={`${ width } x ${ height }`} suffix="pixels" disabled />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Physical Pixels"
                                extra="The total number of pixels in the screen."
                                shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinets !== c?.cabinets}
                            >
                                {({ getFieldValue }) => {
                                    const resolution = selectedCabinet.module.resolution;
                                    const modules = selectedCabinet.modules;
                                    const cabinets = getFieldValue(['cabinets']);
                                    if (!resolution || !modules || !cabinets) {
                                        return <Input suffix="pixels" disabled />;
                                    };

                                    const width = resolution.width * modules.width * cabinets.width;
                                    const height = resolution.height * modules.height * cabinets.height;

                                    return (
                                        <Input value={new Intl.NumberFormat().format(width * height)} suffix="pixels" disabled />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Screen Ratio"
                                extra="The ratio of the screen resolution."
                                shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinets !== c?.cabinets}
                            >
                                {({ getFieldValue }) => {
                                    const resolution = selectedCabinet.module.resolution;
                                    const modules = selectedCabinet.modules;
                                    const cabinets = getFieldValue(['cabinets']);
                                    if (!resolution || !modules || !cabinets) {
                                        return <Input disabled />;
                                    };

                                    const width = resolution.width * modules.width * cabinets.width;
                                    const height = resolution.height * modules.height * cabinets.height;

                                    return (
                                        <Input value={width / height} disabled />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Aspect Ratio"
                                extra="The aspect ratio of the screen resolution."
                                shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinets !== c?.cabinets}
                            >
                                {({ getFieldValue }) => {
                                    const resolution = selectedCabinet.module.resolution;
                                    const modules = selectedCabinet.modules;
                                    const cabinets = getFieldValue(['cabinets']);
                                    if (!resolution || !modules || !cabinets || !cabinets.width || !cabinets.height) {
                                        return <Input disabled />;
                                    };

                                    const width = resolution.width * modules.width * cabinets.width;
                                    const height = resolution.height * modules.height * cabinets.height;
                                    const r = gcd(width, height);

                                    return (
                                        <Input value={`${ width / r }:${ height / r}`} disabled />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left">Screen Properties (weight and power)</Divider>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item
                                label="Weight"
                                extra="The total weight of the screen in kilograms."
                                shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinets !== c?.cabinets}
                            >
                                {({ getFieldValue }) => {
                                    const weight = selectedCabinet.weight;
                                    const cabinets = getFieldValue(['cabinets']);
                                    if (!weight || !cabinets) {
                                        return <Input suffix="kg" disabled />;
                                    };

                                    return (
                                        <Input value={new Intl.NumberFormat().format(weight * cabinets.width * cabinets.height)} suffix="kg" disabled />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Power Max"
                                extra="The maximum power consumption of the screen in watts."
                                shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinets !== c?.cabinets}
                            >
                                {({ getFieldValue }) => {
                                    const power = selectedCabinet.maxPower;
                                    const cabinets = getFieldValue(['cabinets']);
                                    if (!power || !cabinets) {
                                        return <Input suffix="watts" disabled />;
                                    };

                                    return (
                                        <Input value={new Intl.NumberFormat().format(power * cabinets.width * cabinets.height)} suffix="watts" disabled />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Power Max (Amps)"
                                extra="The maximum power consumption of the screen in amps."
                                shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinets !== c?.cabinets}
                            >
                                {({ getFieldValue }) => {
                                    const power = selectedCabinet.maxPower;
                                    const cabinets = getFieldValue(['cabinets']);
                                    if (!power || !cabinets) {
                                        return <Input suffix="amps" disabled />;
                                    };

                                    return (
                                        <Input value={new Intl.NumberFormat().format(power * cabinets.width * cabinets.height / 120)} suffix="amps" disabled />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Required Circuits"
                                extra="The number of required 20 amp circuits."
                                shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinets !== c?.cabinets}
                            >
                                {({ getFieldValue }) => {
                                    const power = selectedCabinet.maxPower;
                                    const cabinets = getFieldValue(['cabinets']);
                                    if (!power || !cabinets) {
                                        return <Input disabled />;
                                    };

                                    const amps = power * cabinets.width * cabinets.height / 120;

                                    return (
                                        <Input value={new Intl.NumberFormat().format(amps / 20)} disabled />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Required Circuits"
                                extra="The number of required 15 amp circuits."
                                shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinets !== c?.cabinets}
                            >
                                {({ getFieldValue }) => {
                                    const power = selectedCabinet.maxPower;
                                    const cabinets = getFieldValue(['cabinets']);
                                    if (!power || !cabinets) {
                                        return <Input disabled />;
                                    };

                                    const amps = power * cabinets.width * cabinets.height / 120;

                                    return (
                                        <Input value={new Intl.NumberFormat().format(amps / 15)} disabled />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card title="Pricing & Requirements" size="small" style={{ marginBottom: '16px' }}>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item
                                label="Total Cost"
                                extra="The total cost of the screen."
                                shouldUpdate={(p: IFormValues, c: IFormValues) => p?.cabinets !== c?.cabinets}
                            >
                                {({ getFieldValue }) => {
                                    const cabinetsWidth = getFieldValue(['cabinets', 'width']);
                                    const cabinetsHeight = getFieldValue(['cabinets', 'height']);
                                    const costValue = selectedCabinet.cost;
                                    if (!costValue || !cabinetsWidth || !cabinetsHeight) {
                                        return <Input prefix="$" disabled />;
                                    };

                                    const cost = parseFloat(`${ costValue }`.replace('$', ''));

                                    return (
                                        <Input value={new Intl.NumberFormat().format(cost * cabinetsWidth * cabinetsHeight)} prefix="$" disabled />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </React.Fragment>
    );
};
