import React from 'react';
import { Modal, Form, Input, Divider, InputNumber, Row, Col, Select } from 'antd';
import { nanoid } from 'nanoid';

import { ICabinet } from 'models/cabinet';

interface IAddCabinetModalProps {
    open: boolean;
    onClose: () => void;
    onAdd: (values: ICabinet) => void;
}

export const AddCabinetModal: React.FC<IAddCabinetModalProps> = ({ open, onClose, onAdd }) => {
    const [form] = Form.useForm<ICabinet>();

    const onCancelClick = () => {
        form.resetFields();
        onClose();
        form.setFieldValue('id', nanoid());
    };

    const onAddClick = async () => {
        try {
            const values = await form.validateFields();
            onAdd(values);

            form.resetFields();
            form.setFieldValue('id', nanoid());
        } catch (error) {
            console.error('Failed to add cabinet', error);
        }
    };

    return (
        <Modal
            title="Add Cabinet"
            open={open}
            onCancel={onCancelClick}
            okText="Add"
            onOk={onAddClick}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    id: nanoid(),
                }}
            >
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>

                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Cost"
                            name="cost"
                            extra="The cost of the cabinet in USD."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={2}
                                inputMode="decimal"
                                style={{ width: '100%' }}
                                suffix="USD"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Type" name="type">
                            <Select>
                                <Select.Option value="fixed-install">Fixed Install</Select.Option>
                                <Select.Option value="portable-indoor">Portable Indoor</Select.Option>
                                <Select.Option value="portable-outdoor">Portable Outdoor</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>

                <Form.Item label="Invoice Description" name="invoiceDescription" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>

                <Divider orientation="left">Module</Divider>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Width" name={['module', 'width']} extra="The width of a single module in millimeters." rules={[{ required: true }]}>
                            <InputNumber
                                precision={2}
                                suffix="mm"
                                inputMode="numeric"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Height" name={['module', 'height']} extra="The height of a single module in millimeters." rules={[{ required: true }]}>
                            <InputNumber
                                precision={2}
                                suffix="mm"
                                inputMode="numeric"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Resolution Width"
                            name={['module', 'resolution', 'width']}
                            extra="The number of pixels in the width of the module."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={0}
                                suffix="px"
                                inputMode="numeric"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Resolution Height"
                            name={['module', 'resolution', 'height']}
                            extra="The number of pixels in the height of the module."
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                precision={0}
                                suffix="px"
                                inputMode="numeric"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label="Pixel Pitch"
                    name={['module', 'pixelPitch']}
                    extra="The distance between pixels in millimeters."
                    rules={[{ required: true }]}
                >
                    <InputNumber
                        precision={3}
                        suffix="mm"
                        inputMode="decimal"
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Divider orientation="left">Cabinet Composition</Divider>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Width" name={['modules', 'width']} extra="The number of modules in the width of the cabinet." rules={[{ required: true }]}>
                            <InputNumber
                                precision={0}
                                inputMode="numeric"
                                suffix="modules"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Height" name={['modules', 'height']} extra="The number of modules in the height of the cabinet." rules={[{ required: true }]}>
                            <InputNumber
                                precision={0}
                                inputMode="numeric"
                                suffix="modules"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Weight" name="weight" extra="The weight of the cabinet in kilograms." rules={[{ required: true }]}>
                            <InputNumber
                                precision={1}
                                inputMode="decimal"
                                suffix="kgs"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Max Power" name="maxPower" extra="The maximum power consumption of the cabinet in watts." rules={[{ required: true }]}>
                            <InputNumber
                                precision={0}
                                inputMode="numeric"
                                suffix="watts"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
