export interface INovaStarMax {
    pixels: number;
    width: number;
    height: number;
}

export interface INovaStar {
    model: string;
    max: INovaStarMax;
    price: number;
} //25.2,15.75

export const novaStars: INovaStar[] = [
    {
        model: 'VX200s-N',
        max: {
            pixels: 1300000, //1.3M
            width: 1920,
            height: 1920,
        },
        price: 399,
    },
    {
        model: 'VX400',
        max: {
            pixels: 2600000, //2.6M
            width: 10240,
            height: 8192,
        },
        price: 1099,
    },
    {
        model: 'VX600',
        max: {
            pixels: 3900000, //3.9M
            width: 10240,
            height: 8192,
        },
        price: 1329,
    },
    {
        model: 'VX1000',
        max: {
            pixels: 6500000, //6.5M
            width: 10240,
            height: 8192,
        },
        price: 1899,
    },
    {
        model: 'VX16S',
        max: {
            pixels: 10400000, //10.4M
            width: 10240,
            height: 6400,
        },
        price: 2929,
    },
    {
        model: 'VX2000 Pro',
        max: {
            pixels: 13000000, //13M
            width: 16384,
            height: 8192,
        },
        price: 3900,
    },
];
